<template lang="pug">
.home
  transition(name="fade")
    page-builder(v-if="!$fetchState.pending" :page="page")
</template>

<script>
import { metaIndexFollowMixin } from '@/mixins/metatagMixin';
import { intercomMixin } from '@/mixins/intercomMixin';

export default {
  name: 'Home',
  auth: false,
  components: {
    'page-builder': () => import('@/components/page/pageBuilder'),
  },
  mixins: [metaIndexFollowMixin, intercomMixin],
  async fetch() {
    if (process.server) {
      this.createIntercomUserHash();
    }

    await this.$store.dispatch('api/getPage', { path: '/home-beta', actualPath: this.$route.path });
  },
  computed: {
    page() {
      return this.$store.getters['api/page'];
    },
  },
  watch: {
    '$auth.loggedIn': {
      handler() {
        this.$fetch();
      },
    },
  },
};
</script>

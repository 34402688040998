export const index = {};

export const metaIndexFollowMixin = {
  head() {
    const { meta_img: metaImg } = ((this.page || {}).page || {}).data || {};
    const { thumb_image: thumbImage } = ((this.page.recipe || {}).data || {});
    const { header_image: headerImage } = ((this.page.category || {}).data || {});

    const isRecipe = this.page.page_type === 'recipe';
    const isCategory = this.page.page_type === 'category';

    return {
      title: this.page.meta_title || '404 - Pagina niet gevonden',
      meta: [
        { hid: 'og:description', property: 'og:description', content: this.page.meta_description },
        ...(!isRecipe && !isCategory && metaImg ? [{ hid: 'og:image', property: 'og:image', content: metaImg }] : [{}]),
        ...(isRecipe && thumbImage ? [{ hid: 'og:image', property: 'og:image', content: thumbImage }] : [{}]),
        ...(isCategory && headerImage ? [{ hid: 'og:image', property: 'og:image', content: headerImage }] : [{}]),
        {
          hid: 'robots',
          name: 'robots',
          content: this.metaIndexFollow,
        },
        ...(this.extendMeta || []),
      ],
    };
  },
  computed: {
    metaIndexFollow() {
      const metaFollow = this.page.meta_follow ? 'follow' : 'nofollow';
      const metaIndex = this.page.meta_index ? 'index' : 'noindex';

      return `${metaIndex}, ${metaFollow}`;
    },
  },
};
